/* Cart Module */
/* this cart handles 2 scenarios, for logged in user, it will save/ get through the api, for guest user, it will save/ get through localstorage */
/* cartSchema = {
	name:String,
	productId: Number,
	bundleId:Number,
	varianceId: Number,
	quantity: Number,
	price: {
		value:Number,
		currency: String
	},
	media:{
		src: String,
		alt: String
	}
} */

export const state = () => ({
	items: [],
	total: {},
})

export const getters = {

	total(state) {
		return state.total
	},
	// count(state) {
	// return state.items.reduce((acc, item) => {
	// total quantity
	//	return acc + item.quantity;
	// }, 0);
	// },

}

export const mutations = {
	setItems(state, payload) {
		if (!payload) { return }
		if (Array.isArray(payload.items)) { state.items = payload.items }
		if (payload.total) { state.total = payload.total }
	},

	setTotal(state, payload) {
		state.total = payload
	},
	addItem(state, payload) {
		state.items.push(payload)
	},
	removeItem(state, payload) {
		const index = state.items.findIndex(
			item => item.productId === payload.productId && (item.varianceId === payload.varianceId || item.bundleId === payload.bundleId),
		)
		state.items.splice(index, 1)
	},
}

export const actions = {
	get({ commit }) {
		// if the user logged in, get the data from api, if not, get it from localstorage through this._vm.storage
		// if (this.$auth.loggedIn) {
			return this.$axios.$get("/v1/cart").then((resp) => {
				commit("setItems", resp)
			})
		// } else {
		// 	commit("setItems", this.$cloneDeep(this._vm.storage.cart))
		// }
	},

	add({ commit }, payload) {
		// if the user logged in, send it to api, if not, save it in localstorage through this._vm.storage
		// if (this.$auth.loggedIn) {
			return this.$axios
				.$post("/v1/cart", {
					productId: payload.productId,
					bundleId: payload.bundleId,
					varianceId: payload.varianceId,
					quantity: payload.quantity,
				})
				.then((resp) => {
					commit("setItems", resp)
				})
		// } else {
		// 	this._vm.storage.cart.push(payload)
		// 	commit("setItems", this.$cloneDeep(this._vm.storage.cart))
		// }
	},
	remove({ commit }, payload) {
		// if the user logged in, remove it through api, if not, remove it from localstorage through this._vm.storage
		// if (this.$auth.loggedIn) {
			return this.$axios.$delete(`/v1/cart/${payload.cartId}`).then((resp) => {
				commit("removeItem", payload)
				// set total
				commit("setTotal", resp.total)
			})
		// } else {
		// 	const index = this._vm.storage.cart.findIndex(
		// 		item =>
		// 			item.productId === payload.productId && (item.varianceId === payload.varianceId || item.bundleId === payload.bundleId),
		// 	)
		// 	this._vm.storage.cart.splice(index, 1)
		// 	commit("setItems", this.$cloneDeep(this._vm.storage.cart))
		// }
	},
	moveToWishlist({ commit, dispatch }, payload) {
		// if the user logged in, remove it through api, if not, remove it from localstorage through this._vm.storage
		// if (this.$auth.loggedIn) {
			return this.$axios
				.$post("/v1/my/wishlist/", {
					productId: payload.productId,
					varianceId: payload.varianceId,
				})
				.then((resp) => {
					dispatch("remove", payload).then(() => dispatch("get")) // to refresh the cart total
				})
		// } else {
		// 	const index = this._vm.storage.cart.findIndex(
		// 		item =>
		// 			item.productId === payload.productId && (item.varianceId === payload.varianceId || item.bundleId === payload.bundleId),
		// 	)
		// 	this._vm.storage.cart.splice(index, 1)
		// 	commit("setItems", this.$cloneDeep(this._vm.storage.cart))
		// }
	},
	// sync({ commit, rootState, dispatch }) {
	// 	// when user login, this function will file which will add all items in local storage to the server and remove it from local storage.
	// 	if (this.$auth.loggedIn) {
	// 		const items = this._vm.storage.cart
	// 		if (items && items.length > 0) {
	// 			items.forEach((item, index) => {
	// 				//! TODO check if the item is already in the server, if so, remove it from local storage
	// 				this.$axios
	// 					.$post("/v1/cart", {
	// 						productId: item.productId,
	// 						bundleId: item.bundleId,
	// 						varianceId: item.varianceId,
	// 						quantity: item.quantity,
	// 					})
	// 					.then(() => {
	// 						this._vm.storage.cart.splice(index, 1)
	// 						commit("setItems", this.$cloneDeep(this._vm.storage.cart))
	// 					})
	// 			})
	// 		}
	// 	}
	// },
	checkout({ commit, rootState, dispatch }) {
		// checkout is available only when user is logged in
		// if (this.$auth.loggedIn) {
			return this.$axios.$post("/v1/my/cart/checkout").then((resp) => {
				commit("setItems", [])
			})
		// }
	},
	setQuantity({ commit, rootState, dispatch }, payload) {
		// if the user logged in, remove it through api, if not, remove it from localstorage through this._vm.storage
		// if (this.$auth.loggedIn) {
			return this.$axios
				.$put("/v1/cart/", {
					productId: payload.productId,
					varianceId: payload.varianceId,
					bundleId: payload.bundleId,
					quantity: payload.quantity,
				})
				.then((resp) => {
					commit("setItems", resp)
				})
		// } else {
		// 	const index = this._vm.storage.cart.findIndex(
		// 		item => item.productId === payload.productId && (item.varianceId === payload.varianceId || item.bundleId === payload.bundleId),
		// 	)
		// 	this._vm.storage.cart[index].quantity = payload.quantity
		// 	commit("setItems", this.$cloneDeep(this._vm.storage.cart))
		// }
	},
	finalize({ commit }) {
		commit("setItems", { items: [], total: {} }) // Commit an empty array to reset the items state
	},
}
