
export default {
	props: {
		itemName: {
			type: String,
			default: "Item",
		},
		width: {
			type: [Number, String],
			default: 800,
		},
		api: {
			type: [String, Function, Boolean],
			default: null,
		},
		getApi: {
			type: [String, Function, Boolean],
			default: null,
		},
		putApi: {
			type: [String, Function, Boolean],
			default: null,
		},
		postApi: {
			type: [String, Function, Boolean],
			default: null,
		},
		getItemMap: {
			type: Function,
			default: null,
		},
		postItemMap: {
			type: Function,
			default: null,
		},
		putItemMap: {
			type: Function,
			default: null,
		},
		default: {
			type: Object,
			default: () => {
				return {}
			},
		},
		model: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			drawerModel: false,
			isEdit: false,
			Edit: this.$t("common.edit"),
			New: this.$t("common.add"),
			isPosting: false,
			isPutting: false,
			isGetting: false,
			item: this.$cloneDeep(this.default),
			keyId: null,
			confirms: [],
			error: null,
			showComponent: false,
			slotKey: new Date().getTime(),
		}
	},
	computed: {
		isPersistent() {
			return this.confirms.length > 0
		},

		bind() {
			return {
				keyId: this.keyId,
			}
		},
	},
	watch: {
		default: {
			handler() {
				this.item = this.$cloneDeep(this.default)
			},
			deep: true,
		},
		drawerModel: {
			handler(v) {
				if (!v) {
					this.$refs.crudDrawer.reset()
					this.item = this.$cloneDeep(this.default)
					this.$emit("closed")
				} else {
					this.$emit("opened")
				}
			},
		},
	},
	// mounted() {
	// 	this.$nuxt.$on("confirm-box", this.confirmBoxHandler);
	// },
	// destroyed() {
	// 	this.$nuxt.$off("confirm-box", this.confirmBoxHandler);
	// },
	methods: {
		forceUpdate() {
			this.slotKey = new Date().getTime()
		},

		joinPaths(arr = []) {
			let query = null
			arr = arr
				.map((segment) => {
					segment = String(segment)
					// if segment has ? or #, remove it and everything after it
					if (segment.includes("?")) {
						query = segment.substring(segment.indexOf("?"))
						segment = segment.substring(0, segment.indexOf("?"))
					}
					if (segment.endsWith("/")) {
						return segment.substring(0, segment.length - 1)
					}

					return segment
				})
				.filter(item => !!item)
			console.log("🚀 ~ file: crud.vue:126 ~ arr=arr.map ~ arr", arr)

			return arr.join("/") + (query || "")
		},
		edit(payload) {
			this.isEdit = true

			if (typeof payload === "object") {
				this.$set(this, "item", this.$cloneDeep(payload))
				this.forceUpdate() // temp fix for view not being rendered correctly
			} else {
				this.keyId = payload
				this.get()
			}
			this.openDrawer()
		},
		new(obj) {
			this.isEdit = false
			if (obj) {
				console.log(obj)
				this.item = { ...this.item, ...obj }
			}
			this.forceUpdate()
			this.openDrawer()
		},
		get() {
			this.error = null

			if (!this.getApi && !this.api) {
				return
			}
			this.isGetting = true
			const url = this.getApi
				? typeof this.getApi === "function"
					? this.getApi(this.bind)
					: this.getApi
				: this.joinPaths([this.api, this.keyId])

			this.$axios
				.$get(url)
				.then((res) => {
					if (this.getItemMap) { res = this.getItemMap(res) }
					Object.assign(this.item, res)
					this.$nextTick(() => {
						this.$emit("loaded", res)
					})
				})
				.catch((e) => {
					this.genericErrorHandler(e)
					this.error = e
				})
				.finally(() => {
					this.isGetting = false
				})
			// setTimeout(() => {
			// 	this.isGetting = false;
			// }, 500);
		},
		post() {
			if (!this.$refs.crudDrawer.validate()) {
				this.scrollToError()
				return
			}

			const item = this.postItemMap ? this.postItemMap(this.$cloneDeep(this.item)) : this.$cloneDeep(this.item)

			if (this.postApi === false || this.api === false) {
				this.close()
				this.$emit("created", item)
				return
			}

			this.isPosting = true
			const url = this.postApi
				? typeof this.postApi === "function"
					? this.postApi(this.bind)
					: this.postApi
				: this.joinPaths([this.api])

			this.$axios
				.$post(url, item)
				.then((res) => {
					this.close()
					this.$toast.success(`${this.$t("common.item-has-been-created", { item: this.itemName })}`)
					this.$emit("created", res)
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.crudDrawer.$form)
				})
				.finally(() => {
					this.isPosting = false
				})
		},
		put() {
			if (!this.$refs.crudDrawer.validate()) {
				this.scrollToError()
				return
			}

			const item = this.putItemMap ? this.putItemMap(this.$cloneDeep(this.item)) : this.$cloneDeep(this.item)

			if (this.putApi === false || this.api === false) {
				this.close()
				this.$emit("updated", item)
				return
			}

			this.isPutting = true
			const url = this.putApi
				? typeof this.putApi === "function"
					? this.putApi(this.bind)
					: this.putApi
				: this.joinPaths([this.api, this.keyId])

			this.$axios
				.$put(url, item)
				.then((res) => {
					this.isPutting = false
					this.close()
					this.$toast.success(`${this.itemName} has been updated successfully`)
					this.$emit("updated", res)
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.crudDrawer.$form)
				})
				.finally(() => {
					this.isPutting = false
				})
		},
		delete(data) {
			if (typeof data === "object") {
				this.keyId = data.id
			} else {
				this.keyId = data
			}

			this.$confirm(this.$t("messages.confirm-delete-text"), { title: this.$t("messages.confirm-delete-title") }).then(
				(isAccepted) => {
					if (isAccepted) {
						this._delete()
					}
				},
			)

			// this._delete();
		},
		_delete() {
			this.isDeleting = true

			this.$axios
				.$delete(this.joinPaths([this.api, this.keyId]), this.item)
				.then((res) => {
					this.isDeleting = false

					this.$toast.success(this.itemName + " " + this.$t("messages.has-been-deleted-successfully"))
					this.$emit("deleted", res)
				})
				.catch((e) => {
					this.genericErrorHandler(e)
				})
		},

		close() {
			this.drawerModel = false
		},

		closeDrawer() {
			this.drawerModel = false
		},
		openDrawer() {
			this.drawerModel = true
		},
		confirmBoxHandler(obj) {
			if (obj.value === true) {
				this.confirms.push(obj.id)
			} else {
				this.confirms = this.confirms.filter(id => id !== obj.id)
			}
		},
		reset() {
			this.item = this.$cloneDeep(this.default)
		},
	},
}
