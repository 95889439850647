import Vue from "vue"
import VueGates from "vue-gates"

Vue.use(VueGates)

Vue.directive("can", {
	inserted(el, bindings, vnode) {
		console.log("nodew", Vue.prototype)

		const $gates = window.$nuxt.$gates
		const element = vnode.elm
		const behavior = bindings.arg
		let hasPermission = false

		if (["any", "all"].includes(behavior)) {
			// const permissions = bindings.value.split("|");
			switch (behavior) {
				case "any":
					hasPermission = $gates.hasAnyPermission(bindings.value)

					break
				case "all":
					hasPermission = $gates.hasAllPermissions(bindings.value)
					break
			}
		} else {
			console.log("perm", bindings.value, $gates.hasPermission(bindings.value))
			hasPermission = $gates.hasPermission(bindings.value)
		}

		const parentElement = element.parentElement
		if (window.$nuxt.$store.state.debug.permissions) {
			element.classList.add("permission")
			const newEl = document.createElement("div")
			newEl.innerHTML = bindings.value || "warning: no permission set"
			if (hasPermission) {
				element.classList.add("has-permission")
			} else {
				element.classList.add("no-permission")

				if (!bindings.value) {
					element.classList.add("no-value-permission")
				}
			}
			element.ondblclick = () => {
				let permissions = []
				if (["any", "all"].includes(behavior)) {
					permissions = bindings.value.split("|")
				} else {
					permissions = [bindings.value]
				}
				window.$nuxt.$emit("show-permission", permissions)
			}
			// parentElement.appendChild(newEl);
		} else if (!hasPermission) {
			parentElement.removeChild(vnode.elm)
		}
		// console.log(
		// 	"per,",
		// 	window.$nuxt.$route.meta.permission + "." + behavior,
		// 	window.$nuxt.$gates.hasPermission(window.$nuxt.$route.meta.permission + "." + behavior)
		// );
	},
})
export default (_context, inject) => {
	Vue.prototype.$gates.hasPermission = function (uiPermission) {
		if (!uiPermission) { return false }

		const permissions = window.$nuxt.$store.state.authExtend.permissions

		const segments = uiPermission.split(".")
		// walk through segments against permissions, once it hits undefined, it should return false
		let current = permissions
		for (const segment of segments) {
			if (current[segment]) {
				if (current[segment]["*"]) {
					return true
				}
				current = current[segment]
			} else {
				return false
			}
		}
		return true
	}

	inject("gates", Vue.prototype.$gates)
}
