
import { debounce } from "~/modules/Debounce.js"
export default {
	data() {
		return {
			searchText: this.$route.query.q || "",
			results: [],
			selectedCategory: {
				text: "All",
				value: undefined,
			},
			isSearching: false,
			throttledAutocomplete: null,
			searchResultsModel: false,
		}
	},
	computed: {
		categories() {
			return this.$store.state.categories
		},
	},
	created() {
		this.throttledAutocomplete = debounce(this.autocomplete, 300)
	},
	mounted() {
		if (this.$route.query.q) {
			this.autocomplete(this.$route.query.q)
		}
	},
	methods: {
		autocomplete(q) {
			if (!q) { return }

			this.isSearching = true
			this.$axios
				.$get("/v1/autocomplete", {
					params: {
						q,
						category: this.selectedCategory.value,
					},
				})
				.then((res) => {
					this.results = res
					this.searchResultsModel = true
				})
				.catch(this.genericErrorHandler)
				.finally(() => {
					this.isSearching = false
				})
		},
		search() {
			this.$router.push(this.localePath({ name: "search", query: { q: this.searchText } })).catch(() => { })
		},
		selectCategory(c) {
			console.log("🚀 ~ selectCategory ~ c:", c)
			this.selectedCategory = {
				text: c.name,
				value: c.categoryId,
			}
		},
		clear() {
			this.searchText = ""
			this.results = []
			this.$router.push({ ...this.$route, query: { ...this.$route.query, q: null } })
		},
		itemClickHandler(item) {
			this.searchText = item
			this.search()
			this.searchResultsModel = false
		},
	},
}
