
import { mapGetters } from "vuex"

export default {
	data() {
		return {
			loading: false,
			otp: "",
		}
	},

	computed: {
		...mapGetters({
			token: "authExtend/token",
		}),
	},
	mounted() {},

	methods: {
		submit() {
			if (!this.$refs.token.validate()) {
				this.scrollToError()
				return
			}
			this.loading = true
			// this.$store.dispatch("updateCode", this.otp);
			this.$store
				.dispatch("authExtend/verifyCode", { code: this.otp })
				.then(() => {
					this.$nuxt.$emit("auth.show", "verify-reset-password-code")
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.token)
				})
				.finally(() => {
					this.loading = false
				})
		},
		resend() {
			this.$store.dispatch("authExtend/sendResetPasswordEmail", this.$store.email)
		},
	},
}
